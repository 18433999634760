<template>
  <BCard class="p-[11px]">
    <div class="text-black text-2xl font-semibold mb-1">
      Ubah Working Space
    </div>
    <b-overlay
      variant="light"
      :show="loading"
      spinner-variant="primary"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <validation-observer ref="formRules"
        v-slot="{ invalid }">
        <b-row>
          <b-col md="8">
          <!-- form -->
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label="Nama"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nama"
                      :rules="{ regex: /^[a-zA-Z0-9 ]*$/, required: true, max: 45 }"
                    >
                      <b-form-input
                        v-model="name"
                        placeholder="Masukkan Nama"
                        :state="
                          errors.length > 0 || submitErrors.name ? false : null
                        "
                        :formatter="capitalize"
                      />
                      <small class="text-danger">{{
                        errors[0] || submitErrors.name
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    class="p-0"
                    label="Skill"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="`Skill`"
                      rules=""
                    >
                      <v-select
                        class="w-100"
                        placeholder="Pilih Skill"
                        :label="'name'"
                        :clearable="true"
                        :options="listSkill"
                        :reduce="skill => skill.id"
                        v-model="skillId"
                      >
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Kapasitas"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Kapasitas"
                      rules="required|numeric"
                    >
                      <b-form-input
                        v-model="capacity"
                        placeholder="Contoh: 80"
                        :state="
                          errors.length > 0 || submitErrors.name ? false : null
                        "
                        :formatter="capitalize"
                      />
                      <small class="text-danger">{{
                        errors[0] || submitErrors.name
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    class="p-0"
                    label="Kantor Operasional"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="`Kantor Operasional`"
                      rules="required"
                    >
                      <v-select
                        class="w-100"
                        placeholder="Pilih Kantor Operasional"
                        :label="'office_name'"
                        :clearable="true"
                        :options="listOffices"
                        :reduce="office => office.id"
                        v-model="officeId"
                      >
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Lokasi"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Lokasi"
                      rules="required"
                    >
                      <b-button
                        variant="warning"
                        class="btn-icon d-flex align-items-center"
                        v-b-modal.modalMap
                      >
                        <span class="k-location h-100 font-bold text-10 align-middle mx-[5px]"/>
                        {{addressName || 'klik untuk memilih lokasi'}}
                        <span class="k-arrow-right-2 h-100 font-bold text-10 align-middle mx-[5px]"/>
                      </b-button>
                      <small class="text-danger">{{
                        errors[0] || submitErrors.province_id
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group
                    label="Detail Lokasi"
                    label-cols-md="4"
                    label-class="font-medium text-7 text-black"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Detail Lokasi"
                      rules="required|min:5"
                    >
                      <b-form-textarea
                        placeholder="Masukkan alamat lengkap kamu
contoh : jl. no desa, rt, rw"
                        v-model="address"
                        :state="
                          errors.length > 0 || submitErrors.address ? false : null
                        "
                      />
                      <small class="text-danger">{{
                        errors[0] || submitErrors.address
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end mt-3">
          <button
            type="kembali"
            class="mr-50 button-secondary"
            @click.prevent="$router.back()"
          >
            Kembali
          </button>
          <button
            class="mr-50 button-primary"
            :disabled="invalid || loadingSubmit || (!lat && !lng)"
            @click.prevent="submit()"
          >
            <b-spinner
              v-if="loadingSubmit"
              small
            />
            Submit
          </button>
        </div>
      </validation-observer>
    </b-overlay>
    <ModalMap @selected="updateDataLocation" :initialPlaces="initialPlaces"/>
  </BCard>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import {
  required,
  min,
  max,
  numeric,
} from '@validations'
import vSelect from 'vue-select'
import { komtimAxiosIns } from '@/libs/axios'
import { capitalize } from '@/libs/helpers'
import { alertError, alertSuccess } from '@toast'
import ModalMap from './ModalMap.vue'

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ModalMap,
  },
  data() {
    return {
      required,
      min,
      max,
      numeric,
      capitalize,
      alertSuccess,
      alertError,

      loading: false,
      loadingSubmit: false,
      submitErrors: '',
      id: this.$route.params.id,
      detailWorkingSpace: {},

      name: '',
      capacity: '',
      address: '',
      lat: null,
      lng: null,
      addressName: '',

      initialPlaces: {
        lat: -7.281931215395999,
        lng: 109.46630203862301,
      },
      listSkill: [],
      listOffices: [],
      skillId: null,
      officeId: null,
    }
  },
  computed: {
    editAction() { return this.id !== undefined },
  },
  async mounted() {
    this.getListSkills()
    this.getListOffices()
    this.getDetailWorkingSpace()
  },
  methods: {
    async submit() {
      this.loadingSubmit = true

      const data = {
        working_space_name: this.name,
        skill_id: Number.isInteger(this.skillId) ? this.skillId : this.detailWorkingSpace.skill_id,
        capacity: parseInt(this.capacity, 10),
        operational_office_id: Number.isInteger(this.officeId) ? this.officeId : this.detailWorkingSpace.operational_office_id,
        address: this.address,
        lat: this.lat,
        lng: this.lng,
      }

      const url = `v1/working_spaces/${this.id}/update`
      await komtimAxiosIns
        .put(url, data)
        .then(() => {
          const text = 'Berhasil mengubah data'
          this.alertSuccess(text)

          this.$router.push({ name: 'work-space' })
          this.loadingSubmit = false
        })
        .catch(error => {
          this.alertError(error)
          this.loadingSubmit = false

          if (error.response.status === 422) {
            this.submitErrors = Object.fromEntries(
              Object.entries(error.response.data.data).map(
                ([key, value]) => [key, value[0]],
              ),
            )
          }
        })
    },
    async getDetailWorkingSpace() {
      const url = `v1/working_spaces/${this.$route.params.id}`
      await komtimAxiosIns
        .get(url)
        .then(async res => {
          const { data } = res.data
          this.detailWorkingSpace = await data

          this.loadForm()
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
        .finally(() => { this.loading = false })
    },
    async getListSkills() {
      this.loading = true
      this.offset = 0
      const params = `active=${true}&type=2`
      const url = `v1/skills/resource?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listSkill = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    async getListOffices() {
      this.loading = true
      this.offset = 0
      const params = `status=${true}`
      const url = `v1/operational_offices/resource?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listOffices = data
        })
        .catch(err => {
          this.alertError(err)
          this.loading = false
        })
    },
    updateDataLocation(data) {
      this.addressName = data.address
      this.lat = data.lat
      this.lng = data.lng

      this.initialPlaces = {
        lat: data.lat,
        lng: data.lng,
      }
    },
    loadForm() {
      this.name = this.detailWorkingSpace.working_space_name
      this.capacity = this.detailWorkingSpace.capacity
      this.address = this.detailWorkingSpace.address
      this.lat = this.detailWorkingSpace.lat
      this.lng = this.detailWorkingSpace.lng
      this.skillId = this.detailWorkingSpace.skill_name
      this.officeId = this.detailWorkingSpace.operational_office_name
      this.initialPlaces = {
        lat: this.detailWorkingSpace.lat,
        lng: this.detailWorkingSpace.lng,
      }

      this.$geocoder.send({
        lat: this.detailWorkingSpace.lat,
        lng: this.detailWorkingSpace.lng,
      }, response => {
        const { results } = response
        if (!results.length) return

        const data = results[0]
        const addressParts = data.formatted_address?.split(',')
        this.addressName = addressParts?.length > 0 ? addressParts[0] : this.address
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss" scoped>
@import './style/Index.scss';
</style>
